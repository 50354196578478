//modules
export const CLEANUP_TOGGLE = "CLEANUP_TOGGLE";
export const CLEANUP_PENDING = "CLEANUP_PENDING";
export const CLEANUP_PENDING_TOGGLE = "CLEANUP_PENDING_TOGGLE";
export const CLEANUP_PENDING_DATA = "CLEANUP_PENDING_DATA";
export const PIN_DATA = "PIN_DATA";
export const PENDING_DATA = "PENDING_DATA";
const stateDefault = {
  isOpen: false,
  pendingOpen: false,
  pinData: null,
  pendingPins: false,
  pendingData: [],
};

export const cleanUpReducer = function (state = stateDefault, action) {
  switch (action.type) {
    case CLEANUP_TOGGLE:
      return {
        ...state,
        isOpen: action.isOpen,
      };

    case CLEANUP_PENDING_TOGGLE:
      return {
        ...state,
        pendingOpen: action.pendingOpen,
      };

    case PIN_DATA:
      return {
        ...state,
        pinData: action.pinData,
      };

    case PENDING_DATA:
      return {
        ...state,
        pendingData: action.pending,
      };

    default:
      return state;
  }
};
