import React, { Component, Fragment } from "react";
import "./List.scss";
import store from "../../store";
import * as _ from "lodash";
import {
  CLEANUP_PENDING,
  CLEANUP_PENDING_TOGGLE,
  CLEANUP_TOGGLE,
  PIN_DATA,
} from "../../services/CleanUp/reducer";
import RaisedButton from "material-ui/RaisedButton";

import CryptoJS from "crypto-js";

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: store.getState().appReducer.loggedIn,
    };

    this.listRef = React.createRef();
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      this.setState({
        loggedIn: store.getState().appReducer.loggedIn,
        last: store.getState().appReducer.data[
          store.getState().appReducer.data.length - 1
        ],
      });
    });
  }

  componentDidUpdate() {
    this.listRef.current
      ? (this.listRef.current.scrollTop = this.state.scrollPosition)
      : null;
  }

  handleLoad() {
    this.props.nextPage(this.state.last, this.listRef.current.scrollTop);
  }

  n(n) {
    return n > 9 ? "" + n : "0" + n;
  }

  onCleanupClick(pin) {
    store.dispatch({
      type: PIN_DATA,
      pinData: pin,
    });

    store.dispatch({
      type: CLEANUP_TOGGLE,
      isOpen: true,
    });

    store.dispatch({
      type: CLEANUP_PENDING_TOGGLE,
      pendingOpen: false,
    });
  }

  render() {
    return (
      <div className="List">
        {this.props.data.length > 0 ? (
          <Fragment>
            <div className="List__Header Mobile_view_list_header">
              <div className="List__Row header-row">
                <div className="header">Cleanup Serial #</div>
                <div className="header">Event Date</div>
                <div className="header">Location</div>
                <div className="header">Participants</div>
                <div className="header">Pounds Collected</div>
                <div className="header">Event Type</div>
                <div className="header">Photos</div>
                <div className="header">Receipts</div>
              </div>
            </div>
            <ul ref={this.listRef} className="List__Scroll">
              {this.props.data.map((pin, index) => {
                let status = _.get(pin, "status", 1);
                if (status == 1) {
                  return (
                    <div key={index} className="stat">
                      <li
                        className="List__Row stat-row"
                        onClick={() => this.onCleanupClick(pin)}
                      >
                        <div className="header">
                          {pin.serialNum}
                        </div>

                        <div className="header">
                          {new Date(
                            _.get(pin, "date.seconds", "0") * 1000
                          ).toDateString()}{" "}
                        </div>
                        <div className="header">{pin.country}</div>
                        <div className="header">{pin.participants}</div>
                        <div className="header">{pin.poundsPulled}</div>
                        <div className="header">{pin.type}</div>
                        <div className="header">{_.size(pin.images)}</div>
                        <div className="header">{_.size(pin.receipts)}</div>
                      </li>
                    </div>
                  );
                }
              })}
              <RaisedButton
                onClick={() => this.handleLoad()}
                className="materialButton"
                style={{ width: "50%", margin: "10px auto" }}
              >
                Load More
              </RaisedButton>
            </ul>
          </Fragment>
        ) : (
          <div style={{ margin: "5vh 2vh" }}>No Data Found</div>
        )}
      </div>
    );
  }
}

export default List;
