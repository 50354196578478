import React, { Component } from "react";
import "./Login.scss";
import store from "../../store";
import firestore from "../../firestore.js";
import firebase from "../../firebase.js";
import classNames from "classnames";
import logo from "../../assets/logo-blue.png";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginModal: store.getState().appReducer.displayLoginModal,
      email: "",
      pass: "",
      error: "",
    };
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      this.setState({
        loginModal: store.getState().appReducer.displayLoginModal,
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  signIn(e) {
    e.preventDefault();
    console.log("Testing whether deployments are taking latest code or not");
    let _this = this;
    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.pass)
      .then(() => {
        console.log("Login Success");

        _this.setState({
          error: "",
        });

        this.props.close();
      })
      .catch((error) => {
        console.log(error.message);
        console.log(error.code);

        if (
          error.code == "auth/user-not-found" ||
          error.code == "auth/wrong-password"
        ) {
          _this.setState({
            error: "Invalid login, please try again.",
          });
        } else {
          _this.setState({
            error: error.message,
          });
        }
      });
  }

  render() {
    return (
      <div
        className={classNames("Login", { displayed: this.state.loginModal })}
      >
        <div className={"Login__container"}>
          {/*<div className={'Login__close-container'}>*/}
          {/*<FontIcon className="material-icons Login__close-icon" color="black"*/}
          {/*onClick={this.props.close}>close</FontIcon>*/}
          {/*</div>*/}
          <div className={"Login__content-container"}>
            <form onSubmit={() => this.signIn()}>
              <div className={"Login__logo-container"}>
                <img className={"Login__logo"} src={logo} />
              </div>
              <span>Email</span>
              <input
                className={"input"}
                type="username"
                placeholder="Email Address"
                value={this.state.email}
                onChange={(e) => {
                  this.setState({
                    email: e.target.value,
                  });
                }}
              />
              <span>Password</span>
              <input
                className={"input"}
                type="password"
                placeholder="Password"
                value={this.state.pass}
                onChange={(e) => {
                  this.setState({
                    pass: e.target.value,
                  });
                }}
              />
              <button
                className="materialButton Login__button"
                onClick={(e) => this.signIn(e)}
              >
                LOGIN
              </button>
              <div className={"Login__error"}>{this.state.error}</div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
