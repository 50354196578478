import firebase from "firebase";
const config = {
  apiKey: "AIzaSyDeVwZWOyqMeZsh064Lje-FKJ0mvvg2STU",
  authDomain: "trash-tracker-49de1.firebaseapp.com",
  databaseURL: "https://trash-tracker-49de1.firebaseio.com",
  projectId: "trash-tracker-49de1",
  storageBucket: "trash-tracker-49de1.appspot.com",
  messagingSenderId: "1009395530738",
};

firebase.initializeApp(config);
export default firebase;
