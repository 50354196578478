import React, { useState } from "react";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./search.scss";

const filter_map = {
  Date: "date",
  Address: "address",
  Country: "country",
  City: "city",
  Location: "locationName",
  Participants: "participants",
  Pounds: "poundsPulled",
  State: "state",
  Status: "status",
  Type: "type",
  ZIP: "zip",
};

const Search = ({
  query,
  filter,
  startDate,
  endDate,
  setHandler,
  setFilter,
  clearFilters,
}) => {
  return (
    <div className="search__container">
      <select
        value={filter}
        onChange={setFilter}
        className="custom-select"
        aria-label="Filter"
      >
        <option value="">Filter</option>
        {Object.entries(filter_map).map((key) => (
          <option key={key[0]} value={key[1]}>
            Filter By {key[0]}
          </option>
        ))}
      </select>
      {filter != "date" ? (
        <input
          type="text"
          placeholder="Search Here"
          value={query}
          onChange={(event) => {
            setHandler("query", event.target.value);
          }}
        />
      ) : (
        <div className="date-filter">
          <DatePicker
            selected={startDate}
            onChange={(date) => setHandler("startDate", date)}
            maxDate={new Date()}
            placeholderText="Choose Starting Date"
            showYearDropdown
            dateFormatCalendar="MMMM"
            yearDropdownItemNumber={15}
            scrollableYearDropdown
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setHandler("endDate", date)}
            minDate={startDate}
            maxDate={new Date()}
            placeholderText="Choose Ending Date"
            showYearDropdown
            dateFormatCalendar="MMMM"
            yearDropdownItemNumber={15}
            scrollableYearDropdown
          />
        </div>
      )}
      <p style={{ margin: "1vh", cursor: "pointer" }} onClick={clearFilters}>
        Clear all
      </p>
    </div>
  );
};

export default Search;
