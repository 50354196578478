import React, { Component } from "react";
import "./Pending.scss";
import store from "../../store";
import classNames from "classnames";
import * as _ from "lodash";
import firestore from "../../firestore.js";
import FontIcon from "material-ui/FontIcon";
import {
  CLEANUP_PENDING_TOGGLE,
  PENDING_DATA,
} from "../../services/CleanUp/reducer";
import LazyLoad from "react-image-lazy-load";
import firebase from "../../firebase.js";
import DatePicker from "material-ui/DatePicker";
import RaisedButton from "material-ui/RaisedButton";
import { userType } from "../../constants/userType";
import emailjs from "@emailjs/browser";

export default class Pending extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cleanups: store.getState().appReducer.data,
      data: [],
      loggedIn: store.getState().appReducer.loggedIn,
      view: "gallery",
      sameType: store.getState().cleanUpReducer.pendingData,
      userType: store.getState().appReducer.userType,
      pinIndex: null,
      pinStatus: null,
      plasticVal: 0,
      nonPlasticVal: 0,
      poundsPulledVal: 0,
      participantsVal: "",
      dateVal: "",
      titleVal: "",
      imageFile: null,
      receiptFile: null,
      submitting: false,
      deleting: false,
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.storageRef = firebase.storage().ref();
    this.unsubscribe = store.subscribe(() => {
      this.setState({
        cleanups: store.getState().appReducer.data,
        loggedIn: store.getState().appReducer.loggedIn,
        sameType: store.getState().cleanUpReducer.pendingData,
      });
    });

    this.getCleanups(0);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let newData = store.getState().cleanUpReducer.pendingData;
    if (this.state.data !== newData[this.state.pinIndex]) {
      console.log("updating pending");
      this.getCleanups(0);
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  close() {
    store.dispatch({
      type: CLEANUP_PENDING_TOGGLE,
      pendingOpen: false,
    });
  }

  next() {
    if (
      this.state.pinIndex !==
      _.indexOf(this.state.sameType, _.last(this.state.sameType))
    ) {
      this.getCleanups(1);
    }
  }

  previous() {
    if (this.state.pinIndex !== 0) {
      this.getCleanups(-1);
    }
  }

  approve() {
    this.setState({
      pinStatus: 1,
    });
  }

  async sendEmail() {
    var constant_params = {
      to_name: this.state.data.locationName,
    };
    emailjs
      .send(
        "service_vqfvcn8",
        "template_lb87dh3",
        constant_params,
        "w2_-vldfm3RJs4eTL"
      )
      .then(
        (result) => {
          console.log("Email send successfully");
        },
        (error) => {
          console.log("Email failed to send");
        }
      );
  }

  deny() {
    this.setState({
      pinStatus: 0,
    });
  }

  yes() {
    let cleanupRef = firestore
      .collection("cleanups")
      .doc(this.state.sameType[this.state.pinIndex].id);
    let _this = this;

    return cleanupRef
      .update({
        status: this.state.pinStatus,
      })
      .then(function () {
        console.log("successfully updated status of cleanup");

        _this.setState({
          pinStatus: null,
        });
        if (_this.state.sameType.length === 1) {
          store.dispatch({
            type: CLEANUP_PENDING_TOGGLE,
            pendingOpen: false,
          });
        }
        _this.state.sameType.splice(_this.state.pinIndex, 1);
        _this.getCleanups(0);
      })
      .then(() => {
        _this.sendEmail();
      })
      .catch(function (error) {
        console.error("Error updating document: ", error);
      });
  }

  reset() {
    this.setState({
      pinStatus: null,
    });
    _this.state.sameType.splice(_this.state.pinIndex, 1);
    _this.getCleanups(0);
  }

  getCleanups(val) {
    let _this = this;
    console.log("getting cleanups");

    if (val === 0) {
      _this.setState({
        data: _this.state.sameType[0],
        plasticVal: _.get(_this.state.sameType[0], "plastic", 0),
        nonPlasticVal: _.get(_this.state.sameType[0], "nonPlastic", 0),
        pinIndex: _.indexOf(_this.state.sameType, _this.state.sameType[0]),
        updating: false,
      });
    } else {
      _this.setState({
        data: _this.state.sameType[_this.state.pinIndex + val],
        plasticVal: _.get(
          _this.state.sameType[_this.state.pinIndex + val],
          "plastic",
          0
        ),
        nonPlasticVal: _.get(
          _this.state.sameType[_this.state.pinIndex + val],
          "nonPlastic",
          0
        ),
        pinIndex: _this.state.pinIndex + val,
        updating: false,
      });
    }
  }

  handleEdit(event) {
    let dateStr = new Date(this.state.data.date.seconds * 1000);
    this.setState({
      editing: !this.state.editing,
      poundsPulledVal: this.state.data.poundsPulled,
      participantsVal: this.state.data.participants,
      dateVal: dateStr,
      titleVal: this.state.data.locationName,
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  submitToDB() {
    let _this = this;
    firestore
      .collection("cleanups")
      .doc(_this.state.data.id)
      .update({
        poundsPulled: this.state.poundsPulledVal,
        plastic: this.state.plasticVal,
        nonPlastic: this.state.nonPlasticVal,
        participants: this.state.participantsVal,
        date: this.state.dateVal,
        locationName: this.state.titleVal,
      })
      .then(
        _this.setState({
          editing: false,
          submitting: false,
          imageFile: null,
          receiptFile: null,
          deleting: false,
        })
      )
      .catch(function (error) {
        console.error("Error updating document: ", error);
      });
  }

  async handleSubmit(e) {
    console.log("Uploading images");

    let _this = this;
    let images = this.state.imageFile;

    this.setState({
      submitting: true,
    });

    if (_.size(images) > 0) {
      for (let i = 0; i < images.length; i++) {
        console.log("on image upload number:", i);

        let file = images[i];

        let metadata = {
          contentType: images[i].type,
        };

        await this.storageRef
          .child("cleanups/" + Math.floor(Math.random() * 12334456) + file.name)
          .put(file, metadata)
          .then(function (snapshot) {
            snapshot.ref.getDownloadURL().then(function (downloadurl) {
              firestore
                .collection("cleanups")
                .doc(_this.state.data.id)
                .update({
                  images: firebase.firestore.FieldValue.arrayUnion({
                    file: downloadurl,
                  }),
                });
              if (i === images.length - 1) {
                _this.uploadReciept();
              }
            });
          });
      }
    } else {
      _this.uploadReciept();
    }
  }

  async uploadReciept(imageFiles) {
    console.log("Uploading receipts");

    let _this = this;
    let images = this.state.receiptFile;

    console.log("upload Reciept images:", images);

    if (_.size(images) > 0) {
      for (let i = 0; i < images.length; i++) {
        console.log("on receipt upload number:", i);

        let file = images[i];

        let metadata = {
          contentType: images[i].type,
        };

        await this.storageRef
          .child("cleanups/" + Math.floor(Math.random() * 12334456) + file.name)
          .put(file, metadata)
          .then(function (snapshot) {
            snapshot.ref.getDownloadURL().then(function (downloadurl) {
              firestore
                .collection("cleanups")
                .doc(_this.state.data.id)
                .update({
                  receipts: firebase.firestore.FieldValue.arrayUnion({
                    file: downloadurl,
                  }),
                });

              if (i === images.length - 1) {
                _this.submitToDB();
              }
            });
          });
      }
    } else {
      _this.submitToDB();
    }
  }

  checkrequired() {
    if (
      this.state.poundsPulledVal != "" &&
      this.state.dateVal != "" &&
      this.state.titleVal != ""
    ) {
      return false;
    } else {
      return true;
    }
  }

  handleImageFileChange = (selectorFiles) => {
    console.log(selectorFiles);
    this.setState({
      imageFile: selectorFiles,
    });
  };

  handleReceiptFileChange = (selectorFiles) => {
    console.log(selectorFiles);
    this.setState({
      receiptFile: selectorFiles,
    });
  };

  handleDeleteReceipt = (fileUrl) => {
    let _this = this;
    _this.setState({
      deleting: true,
    });
    firestore
      .collection("cleanups")
      .doc(this.state.data.id)
      .update({
        receipts: firebase.firestore.FieldValue.arrayRemove({
          file: fileUrl,
        }),
      })
      .then(function () {
        firestore
          .collection("cleanups")
          .doc(_this.state.sameType[_this.state.pinIndex])
          .get()
          .then(function (doc) {
            if (doc.exists) {
              _this.setState({
                data: { id: doc.id, ...doc.data() },
                deleting: false,
              });
            } else {
              console.log("No such document!");
            }
          });
      })
      .catch(function (error) {
        console.error("Error deleting receipt: ", error);
      });
  };

  handleDeleteImage = (fileUrl) => {
    let _this = this;
    _this.setState({
      deleting: true,
    });
    firestore
      .collection("cleanups")
      .doc(_this.state.data.id)
      .update({
        images: firebase.firestore.FieldValue.arrayRemove({
          file: fileUrl,
        }),
      })
      .then(function () {
        _this.setState({
          deleting: false,
        });
      })
      .catch(function (error) {
        console.error("Error deleting image: ", error);
      });
  };

  render() {
    console.log(this.state.data);
    console.log(this.state.plasticVal);
    return (
      <div className={classNames("Pending", this.props.className)}>
        <div className={"CleanUp__title"}>
          {this.state.editing ? (
            <input
              className={"CleanUp__title-input"}
              type="text"
              value={this.state.titleVal}
              onChange={this.handleChange}
              name="titleVal"
            />
          ) : (
            _.get(this.state.data, "locationName", "")
          )}
          <FontIcon
            className="material-icons Cleanup__close-icon"
            color="white"
            onClick={this.close}
          >
            close
          </FontIcon>
        </div>
        {_.size(this.state.sameType) > 1 && (
          <div
            className={
              this.state.editing
                ? "CleanUp__direction CleanUp__none"
                : "CleanUp__direction"
            }
          >
            <div
              className={classNames("direction", {
                disabled: this.state.pinIndex === 0,
              })}
              onClick={() => this.previous()}
            >
              <FontIcon
                className="material-icons direction__icon"
                color="white"
              >
                arrow_back_ios
              </FontIcon>
              Previous
            </div>
            <div
              className={classNames("direction", {
                disabled:
                  this.state.pinIndex ===
                  _.indexOf(this.state.sameType, _.last(this.state.sameType)),
              })}
              onClick={() => this.next()}
            >
              Next
              <FontIcon
                className="material-icons direction__icon"
                color="white"
              >
                arrow_forward_ios
              </FontIcon>
            </div>
          </div>
        )}
        <div className={"CleanUp__pounds"}>
          {this.state.editing ? (
            <input
              className={"CleanUp__pounds-input"}
              name="poundsPulledVal"
              value={this.state.poundsPulledVal}
              onChange={this.handleChange}
            />
          ) : (
            <h2>{_.get(this.state.data, "poundsPulled", "")}</h2>
          )}
          <span>TOTAL POUNDS PULLED</span>
          <br />

          {this.state.editing ? (
            <div className={"CleanUp__participants-container"}>
              <label>Plastic Pounds:</label>
              <input
                className={"CleanUp__participants-input"}
                type="number"
                name="plasticVal"
                value={this.state.plasticVal}
                onChange={(e) => {
                  this.setState({
                    plasticVal: parseFloat(e.target.value),
                    poundsPulledVal:
                      parseFloat(e.target.value) +
                      (this.state.nonPlasticVal > 0
                        ? this.state.nonPlasticVal
                        : 0),
                  });
                }}
              />
            </div>
          ) : (
            <span>Plastic Pounds: {this.state.plasticVal}</span>
          )}

          {this.state.editing ? (
            <div className={"CleanUp__participants-container"}>
              <label>Non-Plastic Pounds:</label>
              <input
                className={"CleanUp__participants-input"}
                type="number"
                name="nonPlasticVal"
                value={this.state.nonPlasticVal}
                onChange={(e) => {
                  this.setState({
                    nonPlasticVal: parseFloat(e.target.value),
                    poundsPulledVal:
                      parseFloat(e.target.value) +
                      (this.state.plasticVal > 0 ? this.state.plasticVal : 0),
                  });
                }}
              />
            </div>
          ) : (
            <span>Non-Plastic Pounds {this.state.nonPlasticVal}</span>
          )}
        </div>
        <div
          className={
            this.state.editing ? "CleanUp__date-editing" : "CleanUp__date"
          }
        >
          {this.state.editing ? (
            <div className={"CleanUp__date-container"}>
              <label style={{ marginRight: "10px" }} htmlFor="dateVal">
                Date
              </label>
              <DatePicker
                name="dateVal"
                className={"CleanUp__date-picker"}
                value={this.state.dateVal}
                onChange={(nil, date) => {
                  this.setState({ dateVal: date });
                }}
              />
            </div>
          ) : (
            new Date(
              _.get(this.state.data, "date.seconds", "0") * 1000
            ).toDateString()
          )}

          <div>
            {this.state.editing ? (
              <div>
                <label
                  style={{ marginRight: "10px" }}
                  htmlFor="participantsVal"
                >
                  Participant(s)
                </label>
                <input
                  className={"CleanUp__participants-input"}
                  name="participantsVal"
                  value={this.state.participantsVal}
                  onChange={this.handleChange}
                />
              </div>
            ) : (
              <div>
                {this.state.data ? (
                  this.state.data.participants ? (
                    <span className={"CleanUp__participants"}>
                      {" "}
                      | {_.get(this.state.data, "participants", "")}{" "}
                      Participant(s)
                    </span>
                  ) : null
                ) : null}
              </div>
            )}
          </div>
        </div>
        <div className={"CleanUp__buttons"}>
          {this.state.editing ? (
            <RaisedButton
              className="materialButton CleanUp__edit-submit"
              onClick={this.handleSubmit}
              disabled={this.checkrequired() || this.state.submitting}
            >
              {this.state.submitting ? "Submitting..." : "Submit Changes"}
            </RaisedButton>
          ) : null}
          {this.state.loggedIn &&
            this.state.userType !== userType.submitter && (
              <RaisedButton
                className="materialButton CleanUp__edit-submit"
                onClick={this.handleEdit}
                disabled={this.state.submitting}
              >
                {this.state.editing ? "Cancel" : "Edit"}
              </RaisedButton>
            )}
        </div>
        {this.state.userType === userType.admin && (
          <div>
            {this.state.pinStatus === null ? (
              <div
                className={
                  this.state.editing
                    ? "CleanUp__approve CleanUp__none"
                    : "CleanUp__approve"
                }
              >
                <div
                  className="CleanUp__approve__deny"
                  onClick={() => this.deny()}
                >
                  <FontIcon
                    className="material-icons CleanUp__approve__icon"
                    color="white"
                  >
                    close
                  </FontIcon>
                </div>
                <div
                  className="CleanUp__approve__approve"
                  onClick={() => this.approve()}
                >
                  <FontIcon
                    className="material-icons CleanUp__approve__icon"
                    color="white"
                  >
                    check
                  </FontIcon>
                </div>
              </div>
            ) : (
              <div className="CleanUp__confirmation">
                Are you sure you want to{" "}
                {this.state.pinStatus === 1 ? "approve" : "deny"} this cleanup?
                <div
                  className="CleanUp__confirmation__choice no"
                  onClick={() => this.reset()}
                >
                  NO
                </div>
                <div
                  className="CleanUp__confirmation__choice yes"
                  onClick={() => this.yes()}
                >
                  YES
                </div>
              </div>
            )}
          </div>
        )}
        <div className={"CleanUp__tab"}>
          <div
            className={classNames(
              this.state.view === "gallery" && "active",
              "tab"
            )}
            onClick={() => this.setState({ view: "gallery" })}
          >
            Gallery
          </div>
          {this.state.loggedIn && (
            <div
              className={classNames(
                this.state.view === "receipts" && "active",
                "tab"
              )}
              onClick={() => this.setState({ view: "receipts" })}
            >
              Receipts
            </div>
          )}
        </div>
        {this.state.view === "gallery" && (
          <div className={"CleanUp__gallery"}>
            {this.state.editing ? (
              <div className={"Form__single-container Form__image-upload"}>
                <input
                  type="file"
                  id="imageFileEdit"
                  accept="image/*"
                  className={" inputFile"}
                  onChange={(e) => this.handleImageFileChange(e.target.files)}
                  multiple
                />
                <label htmlFor="imageFileEdit"> + Add image(s) </label>
                <div className={"Form__single-container justify-center text"}>
                  {_.get(this.state.imageFile, "length", 0) > 0
                    ? _.get(this.state.imageFile, "length", 0) +
                      " Image(s) Added"
                    : ""}
                </div>
              </div>
            ) : null}
            {_.isObject(_.get(this.state.data, "images", null)) ? (
              this.state.data.images.map((image) => (
                <div>
                  <LazyLoad
                    offsetVertical={0}
                    loaderImage
                    originalSrc={image.file}
                    imageProps={{
                      src: require("../../assets/loading.gif"),
                      alt: "Cleanup Location",
                      ref: "image",
                      className: "CleanUp__gallery-image",
                    }}
                  />
                  {this.state.editing ? (
                    <div style={{ display: "flex" }}>
                      <RaisedButton
                        className="materialButton CleanUp__delete-button"
                        onClick={(e) => this.handleDeleteImage(image.file)}
                        disabled={this.state.submitting || this.state.deleting}
                      >
                        Delete
                      </RaisedButton>
                    </div>
                  ) : null}
                </div>
              ))
            ) : (
              <div className={"none"}>There are no images for this cleanup</div>
            )}
          </div>
        )}

        {this.state.view === "receipts" && (
          <div className={"CleanUp__gallery"}>
            {this.state.editing ? (
              <div className={"Form__single-container Form__image-upload"}>
                <input
                  type="file"
                  id="receiptFileEdit"
                  accept="image/*"
                  className={" inputFile"}
                  onChange={(e) => this.handleReceiptFileChange(e.target.files)}
                  multiple
                />
                <label htmlFor="receiptFileEdit"> + Add receipt </label>
                <div className={"Form__single-container justify-center text"}>
                  {_.get(this.state.receiptFile, "length", 0) > 0
                    ? _.get(this.state.receiptFile, "length", 0) +
                      " Receipt(s) Added"
                    : ""}
                </div>
              </div>
            ) : null}

            {_.isObject(_.get(this.state.data, "receipts", null)) ? (
              this.state.data.receipts.map((image) => (
                <div>
                  <LazyLoad
                    offsetTop={0}
                    loaderImage
                    originalSrc={image.file}
                    imageProps={{
                      src: require("../../assets/loading.gif"),
                      alt: "Cleanup Location",
                      ref: "image",
                      className: "CleanUp__gallery-image",
                    }}
                  />
                  {this.state.editing && this.state.data.receipts.length > 1 ? (
                    <div style={{ display: "flex" }}>
                      <RaisedButton
                        className="materialButton CleanUp__delete-button"
                        onClick={(e) => this.handleDeleteReceipt(image.file)}
                        disabled={this.state.submitting || this.state.deleting}
                      >
                        Delete
                      </RaisedButton>
                    </div>
                  ) : null}
                </div>
              ))
            ) : (
              <div className={"none"}>
                There are no receipts for this cleanup
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
