import React, { Component, Fragment } from "react";
import "./CleanUp.scss";
import store from "../../store";
import classNames from "classnames";
import * as _ from "lodash";
import FontIcon from "material-ui/FontIcon";
import { CLEANUP_TOGGLE, PIN_DATA } from "../../services/CleanUp/reducer";
import LazyLoad from "react-image-lazy-load";
import firestore from "../../firestore.js";
import firebase from "../../firebase.js";
import DatePicker from "material-ui/DatePicker";
import RaisedButton from "material-ui/RaisedButton";
import { userType } from "../../constants/userType";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./Cleanup.css";

export default class CleanUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cleanups: store.getState().appReducer.data,
      data: store.getState().cleanUpReducer.pinData,
      loggedIn: store.getState().appReducer.loggedIn,
      pendingPins: store.getState().cleanUpReducer.pendingPins,
      userType: store.getState().appReducer.userType,
      view: "gallery",
      pinStatus: null,
      loading: false,
      poundsPulledVal: _.get(
        store.getState().cleanUpReducer.pinData,
        "poundsPulled",
        0
      ),
      plasticVal: _.get(store.getState().cleanUpReducer.pinData, "plastic", 0),
      nonPlasticVal: _.get(
        store.getState().cleanUpReducer.pinData,
        "nonPlastic",
        0
      ),
      participantsVal: "",
      dateVal: "",
      titleVal: "",
      imageFile: null,
      receiptFile: null,
      submitting: false,
      deleting: false,
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.storageRef = firebase.storage().ref();
    this.unsubscribe = store.subscribe(() => {
      this.setState({
        cleanups: store.getState().appReducer.data,
        data: store.getState().cleanUpReducer.pinData,
        loggedIn: store.getState().appReducer.loggedIn,
        pendingPins: store.getState().cleanUpReducer.pendingPins,
        userType: store.getState().appReducer.userType,
      });
    });

    this.getCleanups();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.data !== prevState.data) {
      console.log("updating cleanups");
      this.getCleanups();
      this.setState({
        deleting: false,
      });
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  close() {
    store.dispatch({
      type: CLEANUP_TOGGLE,
      pendingOpen: false,
    });
  }

  getCleanups() {
    let _this = this;
    let newArray = [];

    this.setState({
      loading: true,
    });

    _this.state.cleanups.forEach((array) => {
      if (
        _.get(array, "lng", 0) === _.get(_this.state.data, "lng", 1) &&
        _.get(array, "status", "1") == 1
      ) {
        if (_.isEmpty(newArray)) {
          newArray = [array];
        } else {
          newArray = [array, ...newArray];
        }
      }
    });

    this.setState({
      loading: false,
    });
  }

  handleEdit(event) {
    let dateStr = new Date(this.state.data.date.seconds * 1000);
    this.setState({
      editing: !this.state.editing,
      poundsPulledVal: this.state.data.poundsPulled,
      participantsVal: this.state.data.participants,
      dateVal: dateStr,
      titleVal: this.state.data.locationName,
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  submitToDB() {
    firestore
      .collection("cleanups")
      .doc(this.state.data.id)
      .update({
        poundsPulled: this.state.poundsPulledVal,
        participants: this.state.participantsVal,
        date: this.state.dateVal,
        locationName: this.state.titleVal,
        plastic: this.state.plasticVal,
        nonPlastic: this.state.nonPlasticVal,
      })
      .then(
        this.setState({
          editing: false,
          submitting: false,
          imageFile: null,
          receiptFile: null,
        })
      )
      .catch(function (error) {
        console.error("Error updating document: ", error);
      });
  }

  async handleSubmit(e) {
    console.log("Uploading images");

    let _this = this;
    let images = this.state.imageFile;

    this.setState({
      submitting: true,
    });

    if (_.size(images) > 0) {
      for (let i = 0; i < images.length; i++) {
        console.log("on image upload number:", i);

        let file = images[i];

        let metadata = {
          contentType: images[i].type,
        };

        await this.storageRef
          .child("cleanups/" + Math.floor(Math.random() * 12334456) + file.name)
          .put(file, metadata)
          .then(function (snapshot) {
            snapshot.ref.getDownloadURL().then(function (downloadurl) {
              firestore
                .collection("cleanups")
                .doc(_this.state.data.id)
                .update({
                  images: firebase.firestore.FieldValue.arrayUnion({
                    file: downloadurl,
                  }),
                });
              if (i === images.length - 1) {
                _this.uploadReciept();
              }
            });
          });
      }
    } else {
      _this.uploadReciept();
    }
  }

  async uploadReciept() {
    console.log("Uploading receipts");

    let _this = this;
    let images = this.state.receiptFile;

    console.log("upload Reciept images:", images);

    if (_.size(images) > 0) {
      for (let i = 0; i < images.length; i++) {
        console.log("on receipt upload number:", i);

        let file = images[i];

        let metadata = {
          contentType: images[i].type,
        };

        await this.storageRef
          .child("cleanups/" + Math.floor(Math.random() * 12334456) + file.name)
          .put(file, metadata)
          .then(function (snapshot) {
            snapshot.ref.getDownloadURL().then(function (downloadurl) {
              firestore
                .collection("cleanups")
                .doc(_this.state.data.id)
                .update({
                  receipts: firebase.firestore.FieldValue.arrayUnion({
                    file: downloadurl,
                  }),
                });

              if (i === images.length - 1) {
                _this.submitToDB();
              }
            });
          });
      }
    } else {
      _this.submitToDB();
    }
  }

  checkrequired() {
    if (
      this.state.poundsPulledVal != "" &&
      this.state.dateVal != "" &&
      this.state.titleVal != ""
    ) {
      return false;
    } else {
      return true;
    }
  }

  handleImageFileChange = (selectorFiles) => {
    console.log(selectorFiles);
    this.setState({
      imageFile: selectorFiles,
    });
  };

  handleReceiptFileChange = (selectorFiles) => {
    console.log(selectorFiles);
    this.setState({
      receiptFile: selectorFiles,
    });
  };

  handleDeleteReceipt = (fileUrl) => {
    this.setState({
      deleting: true,
    });
    firestore
      .collection("cleanups")
      .doc(this.state.data.id)
      .update({
        receipts: firebase.firestore.FieldValue.arrayRemove({
          file: fileUrl,
        }),
      })
      .catch(function (error) {
        console.error("Error deleting receipts: ", error);
      });
  };

  handleDeleteImage = (fileUrl) => {
    this.setState({
      deleting: true,
    });
    firestore
      .collection("cleanups")
      .doc(this.state.data.id)
      .update({
        images: firebase.firestore.FieldValue.arrayRemove({
          file: fileUrl,
        }),
      })
      .catch(function (error) {
        console.error("Error deleting images: ", error);
      });
  };

  handleDeleteCleanup = () => {
    let response = confirm("Are you sure you want to delete this clean up?");

    if (response == true) {
      console.log("Delete cleanup confirmed");
      this.setState({
        deleting: true,
        submitting: true,
      });
      firestore
        .collection("cleanups")
        .doc(this.state.data.id)
        .update({
          status: 0,
        })
        .then(function () {
          store.dispatch({
            type: CLEANUP_TOGGLE,
            pendingOpen: false,
          });
        })
        .catch(function (error) {
          console.error("Error deleting images: ", error);
        });
    } else {
      console.log("Delete cancelled");
    }
  };

  render() {
    return (
      <div className={classNames("CleanUp", this.props.className)}>
        <div className="CleanUp__modal" onClick={this.close} />
        <div className="CleanUp__window">
          <div className={"CleanUp__title"}>
            {this.state.editing ? (
              <input
                className={"CleanUp__title-input"}
                type="text"
                value={this.state.titleVal}
                onChange={this.handleChange}
                name="titleVal"
              />
            ) : (
              _.get(this.state.data, "locationName", "")
            )}
            <FontIcon
              className="material-icons Cleanup__close-icon"
              color="white"
              onClick={this.close}
            >
              close
            </FontIcon>
          </div>

          <div className={"CleanUp__pounds"}>
            {this.state.editing ? (
              <div className={"CleanUp__participants-container"}>
                <input
                  className={"CleanUp__participants-input"}
                  type="number"
                  name="totalPounds"
                  value={this.state.poundsPulledVal}
                  onChange={(e) => {
                    this.setState({
                      poundsPulledVal: parseFloat(e.target.value),
                    });
                  }}
                />
              </div>
            ) : (
              <Fragment>
                <h2>{this.state.poundsPulledVal}</h2>
                <span>TOTAL POUNDS PULLED</span>
              </Fragment>
            )}

            <br />

            {this.state.editing ? (
              <div className={"CleanUp__participants-container"}>
                <label>Plastic Pounds:</label>
                <input
                  className={"CleanUp__participants-input"}
                  type="number"
                  name="plasticVal"
                  value={this.state.plasticVal}
                  onChange={(e) => {
                    this.setState({
                      plasticVal: parseFloat(e.target.value),
                      poundsPulledVal:
                        parseFloat(e.target.value) + this.state.nonPlasticVal,
                    });
                  }}
                />
              </div>
            ) : (
              <span>
                Plastic Pounds: {_.get(this.state.data, "plastic", "N/A")}
              </span>
            )}

            {this.state.editing ? (
              <div className={"CleanUp__participants-container"}>
                <label>Non-Plastic Pounds:</label>
                <input
                  className={"CleanUp__participants-input"}
                  name="nonPlasticVal"
                  type="number"
                  value={this.state.nonPlasticVal}
                  onChange={(e) => {
                    this.setState({
                      nonPlasticVal: parseFloat(e.target.value),
                      poundsPulledVal:
                        parseFloat(e.target.value) + this.state.plasticVal,
                    });
                  }}
                />
              </div>
            ) : (
              <span>
                Non-Plastic Pounds:{" "}
                {_.get(this.state.data, "nonPlastic", "N/A")}
              </span>
            )}
          </div>
          <div
            className={
              this.state.editing ? "CleanUp__date-editing" : "CleanUp__date"
            }
          >
            {this.state.editing ? (
              <div className={"CleanUp__date-container"}>
                <label style={{ marginRight: "10px" }} htmlFor="dateVal">
                  Date
                </label>
                <DatePicker
                  name="dateVal"
                  className={"CleanUp__date-picker"}
                  value={this.state.dateVal}
                  onChange={(nil, date) => {
                    this.setState({ dateVal: date });
                  }}
                />
              </div>
            ) : (
              new Date(
                _.get(this.state.data, "date.seconds", "0") * 1000
              ).toDateString()
            )}
            <div>
              {this.state.editing ? (
                <div>
                  <label
                    style={{ marginRight: "10px" }}
                    htmlFor="participantsVal"
                  >
                    Participant(s)
                  </label>
                  <input
                    className={"CleanUp__participants-input"}
                    name="participantsVal"
                    value={this.state.participantsVal}
                    onChange={this.handleChange}
                  />
                </div>
              ) : (
                <div>
                  {this.state.data.participants && (
                    <span className={"CleanUp__participants"}>
                      {" "}
                      | {_.get(this.state.data, "participants", "")}{" "}
                      Participant(s)
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={"CleanUp__buttons"}>
            {this.state.editing ? (
              <RaisedButton
                className="materialButton CleanUp__edit-submit"
                onClick={this.handleSubmit}
                disabled={this.checkrequired() || this.state.submitting}
              >
                {this.state.submitting ? "Submitting..." : "Submit Changes"}
              </RaisedButton>
            ) : null}
            {this.state.loggedIn && this.state.userType !== userType.viewer && (
              <RaisedButton
                className="materialButton CleanUp__edit-button"
                onClick={this.handleEdit}
                disabled={
                  this.state.submitting ||
                  this.state.userType !== userType.admin
                }
              >
                {this.state.editing ? "Cancel" : "Edit"}
              </RaisedButton>
            )}
          </div>
          {this.state.editing && (
            <div className={"CleanUp__buttons"}>
              <RaisedButton
                className="materialButton CleanUp__edit-button CleanUp__delete-cleanup"
                onClick={this.handleDeleteCleanup}
                disabled={
                  this.state.submitting ||
                  this.state.userType !== userType.admin ||
                  this.state.deleting
                }
              >
                Delete Cleanup
              </RaisedButton>
            </div>
          )}

          <div className={"CleanUp__tab"}>
            <div
              className={classNames(
                this.state.view === "gallery" && "active",
                "tab"
              )}
              onClick={() => this.setState({ view: "gallery" })}
            >
              Gallery
            </div>
            {this.state.loggedIn && (
              <div
                className={classNames(
                  this.state.view === "receipts" && "active",
                  "tab"
                )}
                onClick={() => this.setState({ view: "receipts" })}
              >
                Receipts
              </div>
            )}
          </div>
          {this.state.view === "gallery" && (
            <div className={"CleanUp__gallery"}>
              {this.state.editing ? (
                <div className={"Form__single-container  Form__image-upload"}>
                  <input
                    type="file"
                    id="imageFileEdit"
                    accept="image/*"
                    className={" inputFile"}
                    onChange={(e) => this.handleImageFileChange(e.target.files)}
                    multiple
                  />
                  <label htmlFor="imageFileEdit"> + Add image(s) </label>
                  <div className={"Form__single-container justify-center text"}>
                    {_.get(this.state.imageFile, "length", 0) > 0
                      ? _.get(this.state.imageFile, "length", 0) +
                        " Image(s) Added"
                      : ""}
                  </div>
                </div>
              ) : null}

              {!this.state.loading &&
              _.isObject(_.get(this.state.data, "images", null)) ? (
                <div className="wrapper">
                  {/* 
                  {this.state.data.images.map((image) => (
                    <div>
                      <LazyLoad
                        offsetTop={0}
                        loaderImage
                        originalSrc={image.file}
                        imageProps={{
                          src: require("../../assets/loading.gif"),
                          alt: "Cleanup Location",
                          ref: "image",
                          className: "CleanUp__gallery-image",
                        }}
                      />
                      {this.state.editing ? (
                        <div style={{ display: "flex" }}>
                          <RaisedButton
                            className="materialButton CleanUp__delete-button"
                            onClick={(e) => this.handleDeleteImage(image.file)}
                            disabled={
                              this.state.submitting || this.state.deleting
                            }
                          >
                            Delete
                          </RaisedButton>
                        </div>
                      ) : null}
                    </div>
                  ))}
                  */}

                  <DragDropContext
                    onDragEnd={(result) => {
                      if (!result.destination) return;
                      const items = Array.from(this.state.data.images);
                      const [reorderedItem] = items.splice(
                        result.source.index,
                        1
                      );
                      items.splice(result.destination.index, 0, reorderedItem);
                      this.setState(
                        {
                          ...this.state,
                          data: {
                            ...this.state.data,
                            images: items,
                          },
                        },
                        () => {
                          firestore
                            .collection("cleanups")
                            .doc(this.state.data.id)
                            .update({
                              images: this.state.data.images,
                            })
                            .catch(function (error) {
                              console.error("Error updating document: ", error);
                            });
                        }
                      );
                    }}
                  >
                    <Droppable droppableId="images">
                      {(provided) => (
                        <ul
                          className="images"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {this.state.data.images.map((image, index) => {
                            return (
                              <Draggable
                                key={index}
                                draggableId={index.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <li
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <img
                                      className="imageClass"
                                      src={image.file}
                                      alt="Trying"
                                    />
                                  </li>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {this.state.editing ? (
                    <div style={{ display: "flex" }}>
                      <RaisedButton
                        className="materialButton CleanUp__delete-button"
                        onClick={(e) => this.handleDeleteImage(image.file)}
                        disabled={this.state.submitting || this.state.deleting}
                      >
                        Delete
                      </RaisedButton>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className={"none"}>
                  There are no images for this cleanup
                </div>
              )}
            </div>
          )}

          {this.state.view === "receipts" && (
            <div className={"CleanUp__gallery"}>
              {this.state.editing ? (
                <div className={"Form__single-container  Form__image-upload"}>
                  <input
                    type="file"
                    id="receiptFileEdit"
                    accept="image/*"
                    className={" inputFile"}
                    onChange={(e) =>
                      this.handleReceiptFileChange(e.target.files)
                    }
                    multiple
                  />
                  <label htmlFor="receiptFileEdit"> + Add receipt </label>
                  <div className={"Form__single-container justify-center text"}>
                    {_.get(this.state.receiptFile, "length", 0) > 0
                      ? _.get(this.state.receiptFile, "length", 0) +
                        " Receipt(s) Added"
                      : ""}
                  </div>
                </div>
              ) : null}
              {_.isObject(_.get(this.state.data, "receipts", null)) ? (
                /*
                this.state.data.receipts.map((image) => (
                  <div>
                    <LazyLoad
                      offsetTop={0}
                      loaderImage
                      originalSrc={image.file}
                      imageProps={{
                        src: require("../../assets/loading.gif"),
                        alt: "Cleanup Location",
                        ref: "image",
                        className: "CleanUp__gallery-image",
                      }}
                    />
                    {this.state.editing &&
                    this.state.data.receipts.length > 1 ? (
                      <div style={{ display: "flex" }}>
                        <RaisedButton
                          className="materialButton CleanUp__delete-button"
                          onClick={(e) => this.handleDeleteReceipt(image.file)}
                          disabled={
                            this.state.submitting || this.state.deleting
                          }
                        >
                          Delete
                        </RaisedButton>
                      </div>
                    ) : null}
                  </div>
                ))
                */
                <div className="wrapper">
                  <DragDropContext
                    onDragEnd={(result) => {
                      if (!result.destination) return;
                      const items = Array.from(this.state.data.receipts);
                      const [reorderedItem] = items.splice(
                        result.source.index,
                        1
                      );
                      items.splice(result.destination.index, 0, reorderedItem);
                      this.setState(
                        {
                          ...this.state,
                          data: {
                            ...this.state.data,
                            receipts: items,
                          },
                        },
                        () => {
                          firestore
                            .collection("cleanups")
                            .doc(this.state.data.id)
                            .update({
                              receipts: this.state.data.receipts,
                            })
                            .catch(function (error) {
                              console.error("Error updating document: ", error);
                            });
                        }
                      );
                    }}
                  >
                    <Droppable droppableId="receipts">
                      {(provided) => (
                        <ul
                          className="images"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {this.state.data.receipts.map((image, index) => {
                            return (
                              <Draggable
                                key={index}
                                draggableId={index.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <li
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <img
                                      className="imageClass"
                                      src={image.file}
                                      alt="Trying"
                                    />
                                  </li>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {this.state.editing && this.state.data.receipts.length > 1 ? (
                    <div style={{ display: "flex" }}>
                      <RaisedButton
                        className="materialButton CleanUp__delete-button"
                        onClick={(e) => this.handleDeleteReceipt(image.file)}
                        disabled={this.state.submitting || this.state.deleting}
                      >
                        Delete
                      </RaisedButton>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className={"none"}>
                  There are no receipts for this cleanup
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
