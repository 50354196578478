//modules
import {createStore} from 'redux'
import {combineReducers} from 'redux'

//components
import {statsMenuReducer} from './services/StatsMenu/reducer'
import {overlayReducer} from "./services/Overlay/reducer";
import {modalReducer} from "./services/Modal/reducer";
import {appReducer} from "./services/App/reducer";
import {cleanUpReducer} from "./services/CleanUp/reducer";

// Combine Reducers
const mainAppReducer = combineReducers({
    statsMenuReducer,
    overlayReducer,
    modalReducer,
    appReducer,
    cleanUpReducer
})

export default createStore(mainAppReducer)