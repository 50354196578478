//modules
export const CLEANUP_DATA = 'CLEANUP_DATA';
export const APP_LOGGEDIN = 'APP_LOGGEDIN';
export const APP_DISPLAYLOGIN = 'APP_DISPLAYLOGIN';
export const APP_USER = 'APP_USER';
export const APP_USERTYPE = 'APP_USERTYPE';
export const APP_USERFIRST = 'APP_USERFIRST';
export const APP_USERLAST = 'APP_USERLAST';
export const APP_POUNDS = 'APP_POUNDS';
export const POSITION = 'POSITION';

const stateDefault = {
    data                   : [],
    pendingData: [],
    displayLoginModal: false,
    loggedIn: false,
    user: null,
    userType: 3,
    userFirst: null,
    userLast: null,
    pounds: null,
    scrollPosition: null
};

export const appReducer = function (state = stateDefault, action) {
    switch (action.type) {
        case CLEANUP_DATA:
            return {
                ...state, data: action.data
            };
            break;
        case APP_POUNDS:
            return {
                ...state, pounds: action.pounds
            };
            break;
        case APP_DISPLAYLOGIN:
            return {
                ...state, displayLoginModal: action.displayLoginModal
            };
            break;
        case APP_LOGGEDIN:
            return {
                ...state, loggedIn: action.loggedIn
            };
            break;
        case APP_USER:
            return {
                ...state, user: action.user
            };
            break;
        case APP_USERTYPE:
            return {
                ...state, userType: action.userType
            };
            break;
        case APP_USERFIRST:
            return {
                ...state, userFirst: action.userFirst
            };
            break;
        case APP_USERLAST:
            return {
                ...state, userLast: action.userLast
            };
            break;
        case POSITION:
            return {
                ...state, scrollPosition: action.position
            };
            break;
        default:
            return state;
            break;
    }
};
