import React, { Component } from 'react'
import './Map.scss'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import pinImage from '../../assets/pin.png';
import store from '../../store'
import {CLEANUP_PENDING, CLEANUP_PENDING_TOGGLE, CLEANUP_TOGGLE, PIN_DATA} from "../../services/CleanUp/reducer";

class MapDisplay extends Component {

    constructor(props){
        super(props);

        this.state = {
            data: store.getState().appReducer.data,
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            markerInfo: null
        }

        this.mapData = [];

    }

    componentDidMount() {
        this.unsubscribe = store.subscribe(() => {
            this.setState({
                data: store.getState().appReducer.data
            })
        });
    }

    componentWillUnmount() {
        this.unsubscribe()
    }

    onMarkerClick = (props, marker, e) => {
        console.log('Pin Clicked')

        store.dispatch({
            type: PIN_DATA,
            pinData: marker.data
        })

        store.dispatch({
            type: CLEANUP_TOGGLE,
            isOpen: true
        })

        store.dispatch({
            type: CLEANUP_PENDING_TOGGLE,
            pendingOpen: false
        })

    }

    onMapClicked = (props) => {
        console.log('Map Clicked')
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
                markerInfo: null
            })
        }

        store.dispatch({
            type: CLEANUP_TOGGLE,
            isOpen: false
        })

        store.dispatch({
            type: PIN_DATA,
            pinData: null
        })

        store.dispatch({
            type: CLEANUP_PENDING_TOGGLE,
            pendingOpen: false
        })
    };

    render() {
        const mapOptions = {
            styles: [
                {
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#616161"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "stylers": [
                        {
                            "color": "#9bafc6"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#7c8c9d"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#7c8c9d"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#7c8c9d"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#bdbdbd"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "stylers": [
                        {
                            "color": "#7c8c9d"
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "landscape.man_made",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#7c8c9d"
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural",
                    "stylers": [
                        {
                            "color": "#7c8c9d"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#eeeeee"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "featureType": "poi.attraction",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#7c8c9d"
                        }
                    ]
                },
                {
                    "featureType": "poi.business",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#7c8c9d"
                        }
                    ]
                },
                {
                    "featureType": "poi.government",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#7c8c9d"
                        }
                    ]
                },
                {
                    "featureType": "poi.medical",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#7c8c9d"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#7c8c9d"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#7c8c9d"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                },
                {
                    "featureType": "poi.place_of_worship",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#7c8c9d"
                        }
                    ]
                },
                {
                    "featureType": "poi.school",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#7c8c9d"
                        }
                    ]
                },
                {
                    "featureType": "poi.sports_complex",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#7c8c9d"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "stylers": [
                        {
                            "color": "#7c8c9d"
                        },
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dadada"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#616161"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                },
                {
                    "featureType": "transit.line",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e5e5e5"
                        }
                    ]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#eeeeee"
                        }
                    ]
                },
                {
                    "featureType": "transit.station.airport",
                    "stylers": [
                        {
                            "color": "#7c8c9d"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#64768b"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                }
            ]
        }

        return (
            <Map
                className="Map"
                google={this.props.google}
                initialCenter={{
                    lat: 31.474387,
                    lng: -12.695405
                }}
                zoom={3}
                styles={mapOptions.styles}
                onClick={this.onMapClicked}
            >
                {this.state.data.map((pin, index) => {
                    let status = _.get(pin, 'status', 1)
                    console.log(pin)

                    if (status == 1) {
                        return (
                            <Marker
                                key={index}
                                onClick={this.onMarkerClick}
                                // onMouseover={this.onMouseoverMarker.bind(this)}
                                // onMouseout={this.onMouseoutMarker.bind(this)}
                                name={'Current location'}
                                position={{lat: pin.lat, lng: pin.lng}}
                                data={pin}
                                icon={{
                                    url: pinImage,
                                }}
                            />
                        )
                    }
                })}

                <InfoWindow
                    marker={this.state.activeMarker}
                    visible={this.state.showingInfoWindow}>
                    <div>
                        <div className={'Map__info-title'}>{_.get(this.state.markerInfo, 'locationName', '')}</div><br/>
                        <div className={'Map__info-content'}>
                            {_.get(this.state.markerInfo, 'city', '') != '' &&
                                <div>
                                    <span>City:</span> {_.get(this.state.markerInfo, 'city', 'N/A')}<br/>
                                </div>
                            }
                            <span>Country:</span> {_.get(this.state.markerInfo, 'country', 'N/A')}<br/>
                            <span>Coordinates:</span> {_.get(this.state.markerInfo, 'lat', 'N/A')}, {_.get(this.state.markerInfo, 'lng', 'N/A')}<br/>
                            <span>Type:</span> {_.get(this.state.markerInfo, 'type', 'N/A')}<br/>
                            <span>Date:</span> {new Date(_.get(this.state.markerInfo, 'date.seconds', '0')*1000).toDateString()}<br/>
                            <span>Pounds Pulled:</span> {_.get(this.state.markerInfo, 'poundsPulled', 'N/A')}<br/>
                            <span>Participants:</span> {_.get(this.state.markerInfo, 'participants', 'N/A')}
                        </div>
                    </div>
                </InfoWindow>
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAzM57qmMxJmJUak7Gqo44bdJWFS0cirEY'
})(MapDisplay)
