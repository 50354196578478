//modules
export const OVERLAY_TOGGLE = 'OVERLAY_TOGGLE';
const stateDefault = {
    isDisplayed                   : false
};

export const overlayReducer = function (state = stateDefault, action) {
    switch (action.type) {
        case OVERLAY_TOGGLE:
            return {
                ...state, isDisplayed: action.isDisplayed
            };
            break;
        default:
            return state;
            break;
    }
};