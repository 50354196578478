//modules
export const MENU_TOGGLE = 'MENU_TOGGLE';
const stateDefault = {
    isOpen                   : false
};

export const statsMenuReducer = function (state = stateDefault, action) {
    switch (action.type) {
        case MENU_TOGGLE:
            return {
                ...state, isOpen: !state.isOpen
            };
            break;
        default:
            return state;
            break;
    }
};