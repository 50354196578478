import React, { Component, Fragment } from "react";
import "./Form.scss";
import store from "../../store";
import firestore from "../../firestore.js";
import firebase from "../../firebase.js";
import classNames from "classnames";
import FontIcon from "material-ui/FontIcon";
import RaisedButton from "material-ui/RaisedButton";
import DatePicker from "material-ui/DatePicker";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import * as _ from "lodash";
import TextField from "@material-ui/core/TextField";
import { userType } from "../../constants/userType";

class Form extends Component {
  constructor(props) {
    super(props);

    this.selectTypeDefault = "Select a type";

    this.state = {
      user: store.getState().appReducer.user,
      userType: store.getState().appReducer.userType,
      date: null,
      participants: "",
      poundsPulled: 0,
      plastic: 0,
      nonPlastic: 0,
      type: this.selectTypeDefault,
      sponsoredCleanup: "",
      cleanupName: "",
      location: "",
      suggestions: [],
      address: "",
      streetNumber: "",
      streetName: "",
      city: "",
      state: "",
      country: "",
      zip: "",
      lat: "",
      lng: "",
      imageFile: null,
      receiptFile: null,
      submitting: false,
    };
  }

  componentDidMount() {
    this.storageRef = firebase.storage().ref();

    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);

    this.unsubscribe = store.subscribe(() => {
      this.setState({
        user: store.getState().appReducer.user,
        userType: store.getState().appReducer.userType,
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();

    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("keyup", this.handleKeyUp);
  }

  handleAddressChange = (address) => {
    if (address !== "") {
      this.setState({ address });
    } else {
      this.setState({
        address,
        city: "",
        state: "",
        country: "",
        zip: "",
      });
    }
  };

  checkrequired() {
    if (
      this.state.type != this.selectTypeDefault &&
      this.state.date != null &&
      this.state.poundsPulled != "" &&
      this.state.country != "" &&
      this.state.receiptFile != null &&
      _.size(this.state.receiptFile) != 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  handleImageFileChange = (selectorFiles) => {
    this.setState({
      imageFile: selectorFiles,
    });
  };

  handleReceiptFileChange = (selectorFiles) => {
    this.setState({
      receiptFile: selectorFiles,
    });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        getLatLng(results[0]).then((latlng) => {
          let streetNumber = _.get(
            results[0].address_components.filter(
              (a) => a.types[0] === "street_number"
            )[0],
            "long_name",
            ""
          );
          let street = _.get(
            results[0].address_components.filter(
              (a) => a.types[0] === "route"
            )[0],
            "long_name",
            ""
          );
          let city = _.get(
            results[0].address_components.filter(
              (a) => a.types[0] === "locality"
            )[0],
            "long_name",
            ""
          );
          let state = _.get(
            results[0].address_components.filter(
              (a) => a.types[0] === "administrative_area_level_1"
            )[0],
            "long_name",
            ""
          );
          let country = _.get(
            results[0].address_components.filter(
              (a) => a.types[0] === "country"
            )[0],
            "long_name",
            ""
          );
          let zip = _.get(
            results[0].address_components.filter(
              (a) => a.types[0] === "postal_code"
            )[0],
            "long_name",
            ""
          );

          let streetName =
            street +
            (street !== "" ? ", " : "") +
            city +
            (city !== "" ? ", " : "") +
            state +
            (state !== "" ? ", " : "") +
            country;

          this.setState({
            address: streetName,
            streetNumber,
            streetName,
            city,
            state,
            country,
            zip,
          });
        });
      })
      .catch((error) => console.error(error));
  };

  async getDocumentCount() {
    const collectionRef = firestore.collection("cleanups");
    const querySnapshot = await collectionRef.get();
    return querySnapshot.size;
  }

  async submitToDB(receiptFiles, imageFiles) {
    let reciepts = receiptFiles;
    let images = imageFiles;

    let _this = this;

    let id = await this.getDocumentCount();

    firestore
      .collection("cleanups")
      .add({
        serialNum: id + 1,
        date: this.state.date,
        participants:
          this.state.participants === "" ? null : this.state.participants,
        poundsPulled: this.state.poundsPulled,
        plastic: this.state.plastic,
        nonPlastic: this.state.nonPlastic,
        locationName: this.state.location === "" ? null : this.state.location,
        cleanupName:
          this.state.cleanupName == "" ? null : this.state.cleanupName,
        address: this.state.address === "" ? null : this.state.address,
        streetNumber:
          this.state.streetNumber === "" ? null : this.state.streetNumber,
        streetName: this.state.streetName === "" ? null : this.state.streetName,
        city: this.state.city === "" ? null : this.state.city,
        type:
          this.state.type === this.selectTypeDefault ? null : this.state.type,
        state: this.state.state === "" ? null : this.state.state,
        country: this.state.country === "" ? null : this.state.country,
        zip: this.state.zip === "" ? null : this.state.zip,
        lat: this.state.lat === "" ? null : parseInt(this.state.lat),
        lng: this.state.lng === "" ? null : parseInt(this.state.lng),
        images: _.isObject(images) ? images : null,
        sponsoredCleanup: this.state.sponsoredCleanup
          ? this.state.sponsoredCleanup
          : null,
        status:
          this.state.userType == userType.admin
            ? userType.admin
            : userType.submitter,
        receipts: reciepts,
        user: this.state.user,
      })
      .then(function (docRef) {
        console.log("Document written with ID: ", docRef.id);

        _this.props.close();
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  }

  async handleSubmit(e) {
    console.log("Uploading images");

    let _this = this;
    let images = this.state.imageFile;

    this.setState({
      submitting: true,
    });

    if (_.size(images) > 0) {
      let imageFiles = [];
      for (let i = 0; i < images.length; i++) {
        console.log("on image upload number:", i);

        let file = images[i];

        let metadata = {
          contentType: images[i].type,
        };

        await this.storageRef
          .child("cleanups/" + Math.floor(Math.random() * 12334456) + file.name)
          .put(file, metadata)
          .then(function (snapshot) {
            snapshot.ref.getDownloadURL().then(function (downloadurl) {
              imageFiles.push({ file: downloadurl });

              if (i === images.length - 1) {
                _this.uploadReciept(imageFiles);
              }
            });
          });
      }
    } else {
      this.uploadReciept();
    }
  }

  async uploadReciept(imageFiles) {
    console.log("Uploading receipts");

    let _this = this;
    let images = this.state.receiptFile;

    console.log("upload Reciept images:", images);

    if (_.size(images) > 0) {
      let receiptFiles = [];
      for (let i = 0; i < images.length; i++) {
        console.log("on receipt upload number:", i);

        let file = images[i];

        let metadata = {
          contentType: images[i].type,
        };

        await this.storageRef
          .child("cleanups/" + Math.floor(Math.random() * 12334456) + file.name)
          .put(file, metadata)
          .then(function (snapshot) {
            snapshot.ref.getDownloadURL().then(function (downloadurl) {
              receiptFiles.push({ file: downloadurl });

              if (i === images.length - 1) {
                if (_.isObject(imageFiles)) {
                  _this.submitToDB(receiptFiles, imageFiles);
                } else {
                  _this.submitToDB(receiptFiles);
                }
              }
            });
          });
      }
    } else {
      console.log("error: no images found");
    }
  }

  render() {
    return (
      <div className={classNames("Form", { displayed: !this.props.hidden })}>
        <div className={"Form__container"}>
          <div className={"Form__close-container"}>
            <FontIcon
              className="material-icons Form__close-icon"
              color="black"
              onClick={this.props.close}
            >
              {" "}
              close{" "}
            </FontIcon>
          </div>
          <div className={"Form__content-container"}>
            <h1>ADD A CLEANUP </h1>
            <span>* fields are required </span>
            <div className={"Form__form-container"}>
              <div className={"Form__form-left"}>
                <div className={"Form__single-container"}>
                  <span>Date of Cleanup * </span>
                  <DatePicker
                    hintText="Date"
                    container="inline"
                    className="materialDateInput outline"
                    onChange={(nil, date) => {
                      this.setState({ date });
                    }}
                    value={this.state.date}
                  />
                </div>
                <div className={"Form__double-container"}>
                  <div className={"Form__single-container"}>
                    <span>Participants </span>
                    <input
                      className={"input"}
                      placeholder="Number of Participants"
                      value={this.state.participants}
                      onChange={(e) =>
                        this.setState({ participants: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className={"Form__double-container"}>
                  <div className={"Form__single-container"}>
                    <span>Plastic Pounds: </span>
                    <input
                      className={"input"}
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Amount"
                      value={this.state.plastic}
                      onChange={(e) =>
                        this.setState({
                          plastic: parseFloat(e.target.value),
                          poundsPulled:
                            parseFloat(e.target.value) + this.state.nonPlastic,
                        })
                      }
                    />
                  </div>
                </div>

                <div className={"Form__double-container"}>
                  <div className={"Form__single-container"}>
                    <span>Non-Plastic Pounds:</span>
                    <input
                      className={"input"}
                      type="number"
                      min="0"
                      placeholder="Amount"
                      step="any"
                      value={this.state.nonPlastic}
                      onChange={(e) =>
                        this.setState({
                          nonPlastic: parseFloat(e.target.value),
                          poundsPulled:
                            parseFloat(e.target.value) + this.state.plastic,
                        })
                      }
                    />
                  </div>
                </div>

                <div className={"Form__double-container"}>
                  <div className={"Form__single-container"}>
                    <span>Total Pounds Pulled:</span>
                    {this.state.poundsPulled}
                  </div>
                </div>

                <div className={"Form__single-container"}>
                  <span>Type of cleanup * </span>
                  <select
                    className={"select"}
                    value={this.state.type}
                    onChange={(e) => this.setState({ type: e.target.value })}
                  >
                    <option disabled hidden>
                      {" "}
                      {this.selectTypeDefault}{" "}
                    </option>
                    <option> Beach </option>
                    <option> Boat </option>
                    <option> Dive </option>
                    <option> River </option>
                    <option> Other </option>
                  </select>
                </div>

                <div className={"Form__double-container"}>
                  <div className={"Form__single-container"}>
                    <input
                      type="file"
                      id="imageFile"
                      accept=".jpeg,.jpg,.heic"
                      className={" inputFile"}
                      onChange={(e) =>
                        this.handleImageFileChange(e.target.files)
                      }
                      multiple
                    />
                    <label htmlFor="imageFile"> + Add image(s) </label>
                  </div>
                  <div className={"Form__single-container justify-center text"}>
                    {_.get(this.state.imageFile, "length", 0) > 0
                      ? _.get(this.state.imageFile, "length", 0) +
                        " Image(s) Added"
                      : ""}
                  </div>
                </div>
                <div className={"Form__single-container receipts"}>
                  Receipt(s) *
                  <hr />
                </div>
                <div className={"Form__double-container"}>
                  <div className={"Form__single-container"}>
                    <input
                      type="file"
                      id="receiptFile"
                      accept=".jpeg,.jpg,.heic"
                      className={" inputFile"}
                      onChange={(e) =>
                        this.handleReceiptFileChange(e.target.files)
                      }
                      multiple
                    />
                    <label htmlFor="receiptFile"> + Add receipt </label>
                  </div>
                  <div className={"Form__single-container justify-center text"}>
                    {_.get(this.state.receiptFile, "length", 0) > 0
                      ? _.get(this.state.receiptFile, "length", 0) +
                        " Receipt(s) Added"
                      : ""}
                  </div>
                </div>
              </div>
              <div className={"Form__form-right"}>
                <div>
                  <div className={"Form__single-container"}>
                    <span>Team Name</span>
                    <select
                      className={"select"}
                      placeholder="Team Name"
                      value={this.state.cleanupName}
                      onChange={(e) => {
                        this.setState({
                          ...this.state,
                          cleanupName: e.target.value,
                        });
                      }}
                    >
                       <option value="GT - SV1">GT - SV1 </option>
                      <option value="GT - SV2">GT - SV2</option>
                      <option value="GT1">GT1</option> 
                      <option value="GT2">GT2</option> 
                      <option value="GT3">GT3</option> 
                      <option value="GT4">GT4</option> 
                      <option value="GT Boom">GT Boom</option> 
                      <option value="GT Barge Las Vegas">
                        GT Barge Las Vegas
                      </option>
                       
                      <option value="GT Barge San Fran">
                        GT Barge San Fran
                      </option>
                      <option value="GT Barge Quetzalito">
                        GT Barge Quetzalito
                      </option>
                      <option value=" Java River"> Java River</option>
                      <option value=" Java Beach"> Java Beach</option>
                      <option value=" Java River Boom 1">
                        {" "}
                        Java River Boom 1
                      </option>
                      <option value=" Java River Boom 2">
                        {" "}
                        Java River Boom 2
                      </option>
                      <option value=" Java River Boom 3">
                        {" "}
                        Java River Boom 3
                      </option>
                      <option value=" Java River Boom 4">
                        {" "}
                        Java River Boom 4
                      </option>
                      <option value=" Java River Boom 5">
                        {" "}
                        Java River Boom 5
                      </option>{" "}
                      <option value=" Java River Boom 6">
                        {" "}
                        Java River Boom 6
                      </option>{" "}
                      <option value=" Java River Boom 7">
                        {" "}
                        Java River Boom 7
                      </option>{" "}
                      <option value=" Java River Boom 8">
                        {" "}
                        Java River Boom 8
                      </option>{" "}
                      <option value=" Java River Boom 9">
                        {" "}
                        Java River Boom 9
                      </option>{" "}
                      <option value=" Java River Boom 10">
                        {" "}
                        Java River Boom 10
                      </option>{" "}
                      <option value=" Java River Boom 11">
                        {" "}
                        Java River Boom 11
                      </option>{" "}
                      <option value=" Java River Boom 12">
                        {" "}
                        Java River Boom 12
                      </option>{" "}
                      <option value=" Java River Boom 13">
                        {" "}
                        Java River Boom 13
                      </option>{" "}
                      <option value=" Java River Boom 14">
                        {" "}
                        Java River Boom 14
                      </option>{" "}
                      <option value=" Java River Boom 15">
                        {" "}
                        Java River Boom 15
                      </option>{" "}
                      <option value=" Java River Boom 16">
                        {" "}
                        Java River Boom 16
                      </option>{" "}
                      <option value=" Java River Boom 17">
                        {" "}
                        Java River Boom 17
                      </option>{" "}
                      <option value=" Java River Boom 18">
                        {" "}
                        Java River Boom 18
                      </option>{" "}
                      <option value=" Java River Boom 19">
                        {" "}
                        Java River Boom 19
                      </option>{" "}
                      <option value=" Java River Boom 20">
                        {" "}
                        Java River Boom 20
                      </option>{" "}
                      <option value=" Java River Boom 21">
                        {" "}
                        Java River Boom 21
                      </option>{" "}
                      <option value=" Java River Boom 22">
                        {" "}
                        Java River Boom 22
                      </option>{" "}
                      <option value=" Java River Boom 23">
                        {" "}
                        Java River Boom 23
                      </option>{" "}
                      <option value=" Java River Boom 24">
                        {" "}
                        Java River Boom 24
                      </option>{" "}
                      <option value=" Java River Boom 25">
                        {" "}
                        Java River Boom 25
                      </option>{" "}
                      <option value=" Java River Boom 26">
                        {" "}
                        Java River Boom 26
                      </option>{" "}
                      <option value=" Java River Boom 27">
                        {" "}
                        Java River Boom 27
                      </option>{" "}
                      <option value=" Java River Boom 28">
                        {" "}
                        Java River Boom 28
                      </option>{" "}
                      <option value=" Java River Boom 29">
                        {" "}
                        Java River Boom 29
                      </option>{" "}
                      <option value=" Java River Boom 30">
                        {" "}
                        Java River Boom 30
                      </option>{" "}
                      <option value=" Java River Boom 31">
                        {" "}
                        Java River Boom 31
                      </option>{" "}
                      <option value=" Java River Boom 32">
                        {" "}
                        Java River Boom 32
                      </option>{" "}
                      <option value=" Java River Boom 33">
                        {" "}
                        Java River Boom 33
                      </option>{" "}
                      <option value=" Java River Boom 34">
                        {" "}
                        Java River Boom 34
                      </option>{" "}
                      <option value=" Java River Boom 35">
                        {" "}
                        Java River Boom 35
                      </option>{" "}
                      <option value="Bali Denpasar 1">Bali Denpasar 1</option> 
                      <option value="Bali Denpasar 2">Bali Denpasar 2</option> 
                      <option value="Bali River Boom 1">
                        Bali River Boom 1
                      </option>
                       
                      <option value="Bali River Boom 2">
                        Bali River Boom 2
                      </option>
                      <option value="Bali River Boom 3">
                        Bali River Boom 3
                      </option>
                      <option value="Bali River Boom 4">
                        Bali River Boom 4
                      </option>
                      <option value="Bali River Boom 5">
                        Bali River Boom 5
                      </option>
                      <option value="Bali River Boom 6">
                        Bali River Boom 6
                      </option>
                      <option value="Bali River Boom 7">
                        Bali River Boom 7
                      </option>
                      <option value="Bali River Boom 8">
                        Bali River Boom 8
                      </option>
                      <option value="Bali River Boom 9">
                        Bali River Boom 9
                      </option>
                      <option value="Bali River Boom 10">
                        Bali River Boom 10
                      </option>
                      <option value="Bali River Boom 11">
                        Bali River Boom 11
                      </option>
                      <option value="Bali River Boom 12">
                        Bali River Boom 12
                      </option>
                      <option value="Bali River Boom 13">
                        Bali River Boom 13
                      </option>
                      <option value="Bali River Boom 14">
                        Bali River Boom 14
                      </option>
                      <option value="Bali River Boom 15">
                        Bali River Boom 15
                      </option>
                      <option value="Bali River Boom 16">
                        Bali River Boom 16
                      </option>
                      <option value="Bali River Boom 17">
                        Bali River Boom 17
                      </option>
                      <option value="Bali River Boom 18">
                        Bali River Boom 18
                      </option>
                      <option value="Bali River Boom 19">
                        Bali River Boom 19
                      </option>
                      <option value="Bali River Boom 20">
                        Bali River Boom 20
                      </option>
                      <option value="Bali River Boom 21">
                        Bali River Boom 21
                      </option>
                      <option value="Bali River Boom 22">
                        Bali River Boom 22
                      </option>
                      <option value="Bali River Boom 23">
                        Bali River Boom 23
                      </option>
                      <option value="Bali River Boom 24">
                        Bali River Boom 24
                      </option>
                      <option value="Bali River Boom 25">
                        Bali River Boom 25
                      </option>
                      <option value="Bali River Boom 26">
                        Bali River Boom 26
                      </option>
                      <option value="Bali River Boom 27">
                        Bali River Boom 27
                      </option>
                      <option value="Bali River Boom 28">
                        Bali River Boom 28
                      </option>
                      <option value="Bali River Boom 29">
                        Bali River Boom 29
                      </option>
                      <option value="Bali River Boom 30">
                        Bali River Boom 30
                      </option>
                      <option value="Bali River Boom 31">
                        Bali River Boom 31
                      </option>
                      <option value="Bali River Boom 32">
                        Bali River Boom 32
                      </option>
                      <option value="Bali River Boom 33">
                        Bali River Boom 33
                      </option>
                      <option value="Bali River Boom 34">
                        Bali River Boom 34
                      </option>
                      <option value="Bali River Boom 35">
                        Bali River Boom 35
                      </option>
                      <option value="Bali River Boom 36">
                        Bali River Boom 36
                      </option>
                      <option value="Bali River Boom 37">
                        Bali River Boom 37
                      </option>
                      <option value="Bali River Boom 38">
                        Bali River Boom 38
                      </option>
                      <option value="Bali River Boom 39">
                        Bali River Boom 39
                      </option>
                      <option value="Bali River Boom 40">
                        Bali River Boom 40
                      </option>
                      <option value="Bali River Boom 41">
                        Bali River Boom 41
                      </option>
                      <option value="Bali River Boom 42">
                        Bali River Boom 42
                      </option>
                      <option value="Bali River Boom 43">
                        Bali River Boom 43
                      </option>
                      <option value="Bali River Boom 44">
                        Bali River Boom 44
                      </option>
                      <option value="Bali River Boom 45">
                        Bali River Boom 45
                      </option>
                       
                      <option value="Bali Jembrana River">
                        Bali Jembrana River
                      </option>
                       
                      <option value="Bali Jembrana Fleet">
                        Bali Jembrana Fleet
                      </option>
                       
                      <option value="Bali Jembrana Beach">
                        Bali Jembrana Beach
                      </option>
                       <option value="Bali Medewi">Bali Medewi</option> 
                      <option value="FL - Osborne">FL - Osborne</option> 
                      <option value="FL - Pennekamp">FL - Pennekamp</option> 
                      <option value="FL - Local">FL - Local</option>
                      <option value="FL - Alex"> FL - Alex</option>
                      <option value="FL - Alex CIC"> FL - Alex CIC</option>
                      <option value="CIC FL - Strike"> CIC FL - Strike</option>
                    </select>
                  </div>
                  <div className={"Form__single-container"}>
                    <span>Sponsored Cleanup</span>
                    <select
                      className={"select"}
                      placeholder="Sponsored Cleanup"
                      value={this.state.sponsoredCleanup}
                      onChange={(e) => {
                        this.setState({
                          sponsoredCleanup: e.target.value,
                        });
                      }}
                    >
                      <option></option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleAddressChange}
                    onSelect={this.handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                    }) => (
                      <Fragment>
                        <div className={"Form__single-container"}>
                          <span>Address / Location * </span>
                          <input
                            {...getInputProps({
                              placeholder: "Address",
                              className: "location-search-input input",
                            })}
                          ></input>
                        </div>
                        <div className={"dropdown"}>
                          <div className="autocomplete-dropdown-container">
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                    padding: 10,
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                    padding: 10,
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                  key={suggestion.placeId}
                                >
                                  <span>{suggestion.description} </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className={"Form__single-container"}>
                          <span>Latitude </span>
                          <input
                            className={"input"}
                            placeholder="Latitude"
                            value={this.state.lat}
                            onChange={(e) => {
                              this.setState({
                                lat: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className={"Form__single-container"}>
                          <span>Longitude </span>
                          <input
                            className={"input"}
                            placeholder="Longitude"
                            value={this.state.lng}
                            onChange={(e) => {
                              this.setState({
                                lng: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className={"Form__single-container"}>
                          <span className={classNames("disabled")}> City </span>
                          <input
                            className={"input"}
                            placeholder="City"
                            value={this.state.city}
                            onChange={(e) => {
                              this.setState({
                                city: e.target.value,
                              });
                            }}
                            disabled={true}
                          />
                        </div>
                        <div className={"Form__single-container"}>
                          <span className={classNames("disabled")}>
                            {" "}
                            State / Province / Region{" "}
                          </span>
                          <input
                            className={"input"}
                            placeholder="State/Province/Region"
                            value={this.state.state}
                            onChange={(e) => {
                              this.setState({
                                state: e.target.value,
                              });
                            }}
                            disabled={true}
                          />
                        </div>
                        <div className={"Form__single-container"}>
                          <span className={classNames("disabled")}>
                            {" "}
                            Country{" "}
                          </span>
                          <input
                            className={"input"}
                            placeholder="Country"
                            value={this.state.country}
                            onChange={(e) => {
                              this.setState({
                                country: e.target.value,
                              });
                            }}
                            disabled={true}
                          />
                        </div>
                      </Fragment>
                    )}
                  </PlacesAutocomplete>
                </div>
              </div>
            </div>
          </div>
          <RaisedButton
            label={
              this.state.submitting ? "Submitting.." : "Submit Information"
            }
            className="materialButton"
            onClick={(e) => this.handleSubmit(e)}
            // disabled={this.checkrequired() || this.state.submitting}
          />
        </div>
      </div>
    );
  }
}

export default Form;
