//modules
export const MODAL_TOGGLE = 'MODAL_TOGGLE';
const stateDefault = {
    isDisplayed                   : false
};

export const modalReducer = function (state = stateDefault, action) {
    switch (action.type) {
        case MODAL_TOGGLE:
            return {
                ...state, isDisplayed: !state.isDisplayed
            };
            break;
        default:
            return state;
            break;
    }
};