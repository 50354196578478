import React, { Component } from "react";
import "./statsMenu.scss";
import logo from "../../assets/logo.svg";
import PieChart from "react-minimal-pie-chart";
import DatePicker from "material-ui/DatePicker";
import RaisedButton from "material-ui/RaisedButton";
import store from "../../store";
import classNames from "classnames";
import * as _ from "lodash";
import moment from "moment";
import { CLEANUP_DATA } from "../../services/App/reducer";
import firebase from "../../firebase.js";
import { OVERLAY_TOGGLE } from "../../services/Overlay/reducer";
import { MODAL_TOGGLE } from "../../services/Modal/reducer";
import {
  CLEANUP_PENDING_TOGGLE,
  CLEANUP_TOGGLE,
} from "../../services/CleanUp/reducer";
import { MENU_TOGGLE } from "../../services/StatsMenu/reducer";
import exportFromJSON from "export-from-json";
import { userType } from "../../constants/userType";

class StatsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statsMenu: store.getState().statsMenuReducer,
      pounds: store.getState().appReducer.pounds,
      data: _.get(store.getState().appReducer, "data", []),
      loggedIn: store.getState().appReducer.loggedIn,
      firstName: store.getState().appReducer.userFirst,
      lastName: store.getState().appReducer.userLast,
      userType: store.getState().appReducer.userType,
      modal: store.getState().modalReducer,
      startDate: null,
      endDate: null,
      defaultArray: null,
      filteredArray: null,
      filter: false,
    };
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      this.setState({
        statsMenu: store.getState().statsMenuReducer,
        data: store.getState().appReducer.data,
        pounds: store.getState().appReducer.pounds,
        loggedIn: store.getState().appReducer.loggedIn,
        firstName: store.getState().appReducer.userFirst,
        lastName: store.getState().appReducer.userLast,
        userType: store.getState().appReducer.userType,
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  filter() {
    let filterDate;

    if (!this.state.filter) {
      this.setState({
        defaultArray: this.state.data,
        filter: true,
      });
      filterDate = this.state.data;
    } else {
      filterDate = this.state.defaultArray;
    }

    if (this.state.startDate != null) {
      console.log(this.state.startDate);
      filterDate = _.filter(filterDate, (array) => {
        return moment(
          new Date(array.date.seconds * 1000).toDateString()
        ).isAfter(this.state.startDate);
      });
    }

    if (this.state.endDate != null) {
      console.log(this.state.endDate);
      filterDate = _.filter(filterDate, (array) => {
        return moment(
          new Date(array.date.seconds * 1000).toDateString()
        ).isBefore(this.state.endDate);
      });
    }

    store.dispatch({
      type: CLEANUP_DATA,
      data: filterDate,
    });
  }

  clear() {
    store.dispatch({
      type: CLEANUP_DATA,
      data: this.state.defaultArray,
    });

    this.setState({
      defaultArray: null,
      filter: false,
    });
  }

  logOut() {
    firebase
      .auth()
      .signOut()
      .then(function () {
        console.log("Logged out");
      })
      .catch(function (error) {
        console.log("an error occurred");
      });
  }

  showPending() {
    store.dispatch({
      type: CLEANUP_PENDING_TOGGLE,
      pendingOpen: true,
    });

    store.dispatch({
      type: CLEANUP_TOGGLE,
      isOpen: false,
    });

    if (this.state.statsMenu.isOpen) {
      store.dispatch({
        type: MENU_TOGGLE,
      });
    }
  }

  export() {
    let data = this.state.data.filter((data) => data.status !== 0);
    const fileName = "cleanups";
    const exportType = "csv";

    exportFromJSON({ data, fileName, exportType });
  }

  toggleModal() {
    store.dispatch({
      type: MODAL_TOGGLE,
    });

    store.dispatch({
      type: OVERLAY_TOGGLE,
      isDisplayed: !this.state.modal.isDisplayed,
    });
  }

  render() {
    const colorOptions = [
      { color: "#D78A8F" },
      { color: "#71CFCD" },
      { color: "#A06BC0" },
      { color: "#EEE1A6" },
      { color: "#bfeea0" },
      { color: "#eed2b2" },
      { color: "#98eed9" },
    ];

    let pieData = [];
    let types =
      _.get(this.state.data, "status", 1) == 1 &&
      _.countBy(this.state.data, "type");

    for (let type in types) {
      var i = Object.keys(types).indexOf(type);
      pieData.push({
        value: types[type],
        color: colorOptions[i].color,
        name: type == "undefined" ? "Other" : type,
      });
    }

    let pending = 0;
    this.state.data.map((pound) => {
      let status = _.get(pound, "status", 1);
      if (status == 2) {
        pending++;
      }
    });

    return (
      <div
        className={classNames("statsMenu", {
          open: this.state.statsMenu.isOpen,
        })}
      >
        <div className={"statsMenu__container"}>
          <div>
            <div className={"statsMenu__logo-container"}>
              <img className={"statsMenu__logo"} src={logo} />
              TRASH TRACKER
            </div>
            <div className={"statsMenu__user"}>
              <span hidden={this.state.firstName === null}>
                Welcome, {this.state.firstName} {this.state.lastName}
              </span>
            </div>

            {this.state.loggedIn && this.state.pounds !== null && (
              <div className={"statsMenu__pie-container"}>
                {console.log("Value is: ", this.state.pounds)}
                <h1>{this.state.pounds.toLocaleString()}</h1>
                <h2>POUNDS PULLED-TO-DATE</h2>
              </div>
            )}

            {this.state.loggedIn &&
              (this.state.userType === userType.admin ||
                this.state.userType === userType.submitter ||
                this.state.userType === userType.manager) && (
                <div className={"statsMenu__user"}>
                  <RaisedButton
                    onClick={() => this.toggleModal()}
                    hidden={!this.state.loggedIn}
                    label="Add a Cleanup"
                    className="materialButton"
                  />
                </div>
              )}

            {this.state.loggedIn && this.state.userType !== userType.viewer && (
              <div className={"statsMenu__user"}>
                <RaisedButton
                  onClick={() => this.showPending()}
                  disabled={
                    pending === 0 ||
                    (this.state.userType !== userType.admin &&
                      this.state.userType !== userType.manager)
                  }
                  label={pending + " Pending Cleanups"}
                  className="materialButton"
                />
              </div>
            )}

            <div className={"statsMenu__login"} hidden={!this.state.loggedIn}>
              <span onClick={() => this.logOut()}>Log Out</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StatsMenu;
